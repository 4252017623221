import Navigation from "./Navigation"

function Heading(props) {

    return(

        <header id="Heading_Card" class="d-flex flex-wrap justify-content-center py-3 mb-4">
            <div id="Header_Border" class="p-2 border border-5 border-white border-start-0 border-end-0">
                <p id="Name" class="h1">
                    {props.heading}
                </p>
            </div>
            <Navigation
                cssID={props.cssNavID} 
                linkImg1={props.navLinkImg1}
                siteLink1={props.navSiteLink1}
                linkImg2={props.navLinkImg2}
                siteLink2={props.navSiteLink2}
                linkImg3={props.navLinkImg3}
                siteLink3={props.navSiteLink3}
                linkImg4={props.navLinkImg4}
                siteLink4={props.navSiteLink4}
                linkImg5={props.navLinkImg5}
                siteLink5={props.navSiteLink5}
            />
        </header>

    )

}

export default Heading;
