function Navigation(props) {

    return(

        <ul id={props.cssID} class="nav nav-fill">
            <li class="nav-item h5 p-1">
                <a class="nav-link link-light" href={props.siteLink1} rel="noreferrer" target="_blank">{props.linkImg1}</a>
            </li>
            <li class="nav-item h5 p-1">
                <a class="nav-link link-light" href={props.siteLink2} rel="noreferrer" target="_blank">{props.linkImg2}</a>
            </li>
            <li class="nav-item h5 p-1">
                <a class="nav-link link-light" href={props.siteLink3} rel="noreferrer" target="_blank">{props.linkImg3}</a>
            </li>
            <li class="nav-item h5 p-1">
                <a class="nav-link link-light" href={props.siteLink4} rel="noreferrer" target="_blank">{props.linkImg4}</a>
            </li>
            <li class="nav-item h5 p-1">
                <a class="nav-link link-light" href={props.siteLink5} rel="noreferrer" target="_blank">{props.linkImg5}</a>
            </li>
        </ul>

    )

}

export default Navigation;