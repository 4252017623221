import './App.css';
import Heading from "./components/Heading";
import Card from "./components/Card";
import AltCard from "./components/AltCard";

function App() {

  const nameStr = "Derek Evans";

  return (

    <div>

      <Heading
        heading={nameStr}
        cssNavID={"Header-Nav"}
        navLinkImg1={
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
          </svg>
        }
        navSiteLink1="https://www.linkedin.com/in/derek-evans01"
        navLinkImg2={
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
          </svg>
        }
        navSiteLink2="https://github.com/REPNOT"
        navLinkImg3={
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-behance" viewBox="0 0 16 16">
            <path d="M4.654 3c.461 0 .887.035 1.278.14.39.07.711.216.996.391.286.176.497.426.641.747.14.32.216.711.216 1.137 0 .496-.106.922-.356 1.242-.215.32-.566.606-.997.817.606.176 1.067.496 1.348.922.281.426.461.957.461 1.563 0 .496-.105.922-.285 1.278a2.317 2.317 0 0 1-.782.887c-.32.215-.711.39-1.137.496a5.329 5.329 0 0 1-1.278.176L0 12.803V3h4.654zm-.285 3.978c.39 0 .71-.105.957-.285.246-.18.355-.497.355-.887 0-.216-.035-.426-.105-.567a.981.981 0 0 0-.32-.355 1.84 1.84 0 0 0-.461-.176c-.176-.035-.356-.035-.567-.035H2.17v2.31c0-.005 2.2-.005 2.2-.005zm.105 4.193c.215 0 .426-.035.606-.07.176-.035.356-.106.496-.216s.25-.215.356-.39c.07-.176.14-.391.14-.641 0-.496-.14-.852-.426-1.102-.285-.215-.676-.32-1.137-.32H2.17v2.734h2.305v.005zm6.858-.035c.286.285.711.426 1.278.426.39 0 .746-.106 1.032-.286.285-.215.46-.426.53-.64h1.74c-.286.851-.712 1.457-1.278 1.848-.566.355-1.243.566-2.06.566a4.135 4.135 0 0 1-1.527-.285 2.827 2.827 0 0 1-1.137-.782 2.851 2.851 0 0 1-.712-1.172c-.175-.461-.25-.957-.25-1.528 0-.531.07-1.032.25-1.493.18-.46.426-.852.747-1.207.32-.32.711-.606 1.137-.782a4.018 4.018 0 0 1 1.493-.285c.606 0 1.137.105 1.598.355.46.25.817.532 1.102.958.285.39.496.851.641 1.348.07.496.105.996.07 1.563h-5.15c0 .58.21 1.11.496 1.396zm2.24-3.732c-.25-.25-.642-.391-1.103-.391-.32 0-.566.07-.781.176-.215.105-.356.25-.496.39a.957.957 0 0 0-.25.497c-.036.175-.07.32-.07.46h3.196c-.07-.526-.25-.882-.497-1.132zm-3.127-3.728h3.978v.957h-3.978v-.957z"/>
          </svg>
        }
        navSiteLink3="https://www.behance.net/DE_Portfolio"
        navLinkImg4={
          <img src="CodePen_Logo.ico" alt="CodePen Logo" title="Link to CodePen profile for Derek Evans" height="31px" width="31px" />
        }
        navSiteLink4="https://codepen.io/DE_NLS/"
        navLinkImg5={
          <img src="icons8-tableau-software-50.png" alt="Tableau Logo" title="Link to Tableau Public Cloud profile for Derek Evans" height="31px" width="31px" />
        }
        navSiteLink5="https://public.tableau.com/app/profile/derek5724"
      />

      <div id="TL_Card" class="uk-flex uk-flex-center">
        <Card 
          heading="Quick Fin"
          Icon={"python-logo-only.svg"}
          IconHeight={"130"}
          IconWidth={"130"}
          SiteText={"View Site"}
          AltText={"Documentation wedsite for the Quick Fin Python Module"}
          Site={"https://quickfin.techbyderek.com/"}
          body={
            <span>
              A
              <a href="https://www.python.org/" target="_blank" rel="noreferrer">&nbsp;Python&nbsp;</a>
              module providing instant access to live and historical stock market price data and automated
              <a href="https://plotly.com/" target="_blank" rel="noreferrer">&nbsp;Plotly&nbsp;</a> 
              data visualization generators.
            </span>
          }
        />

        <Card 
          heading="U.S. Power Industry Dashboard"
          Icon={"power_bi.png"}
          IconHeight={"160"}
          IconWidth={"160"}
          SiteText={"View Dashboard"}
          AltText={"Microsoft Power BI Dashboard"}
          Site={"https://app.powerbi.com/view?r=eyJrIjoiMDg2MmVjMjItMGMwMi00MmRhLTlhODQtMjYxOGI3ODk3ZjUwIiwidCI6IjA0ZWFmYmNhLWVlMGUtNGJlNC1hZTdjLTlhOTI4YmY3N2FlOCIsImMiOjN9"}
          body={
            <span>
              An interactive dashboard containing custom reports generated using the latest data available
              from the&nbsp;
              <a href="https://www.eia.gov/" target="_blank" rel="noreferrer">U.S. Energy Information Administration</a>, presented with&nbsp; 
              <a href="https://www.microsoft.com/en-us/power-platform/products/power-bi/" target="_blank" rel="noreferrer">Microsoft Power BI</a>.
            </span>
          }
        />

        <Card 
          heading="Stock Market Dashboard"
          Icon={"power_bi.png"}
          IconHeight={"160"}
          IconWidth={"160"}
          SiteText={"View Dashboard"}
          AltText={"Microsoft Power BI Dashboard"}
          Site={"https://app.powerbi.com/view?r=eyJrIjoiZGUyZWU1YTktM2ExMC00MjIzLThjM2QtMjM1ZjA0NzQ1NWQ3IiwidCI6IjA0ZWFmYmNhLWVlMGUtNGJlNC1hZTdjLTlhOTI4YmY3N2FlOCIsImMiOjN9&pageName=ReportSectionfabb77846760716c5059"}
          body={
            <span>
              An interactive stock market dashboard, 
              containing historical price data for over 5000 individual securities dating back to 1972,
              presented with&nbsp; 
              <a href="https://www.microsoft.com/en-us/power-platform/products/power-bi/" target="_blank" rel="noreferrer">Microsoft Power BI</a>.
            </span>
          }
        />
      </div>

      <div id="TL_Card" class="uk-flex uk-flex-center">
        <Card 
            heading="Ticket Stream"
            Icon={"streamlit-logo-primary-colormark-darktext.png"}
            IconHeight={"270"}
            IconWidth={"270"}
            SiteText={"View App"}
            AltText={"Streamlit application"}
            Site={"https://www.ticketstream.techbyderek.com/"}
            body={
              <span>
                An interactive live event search application built with&nbsp;
                <a href="https://streamlit.io/" target="_blank" rel="noreferrer">Streamlit</a>
                &nbsp;and&nbsp;
                <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap</a>
                , powered by&nbsp;
                <a href="https://www.ticketmaster.com/" target="_blank" rel="noreferrer">Ticketmaster</a>.
                &nbsp;
              </span>
            }
          />
        <Card 
          heading="Fin Stream"
          Icon={"streamlit-logo-primary-colormark-darktext.png"}
          IconHeight={"270"}
          IconWidth={"270"}
          SiteText={"View App"}
          AltText={"Streamlit Application"}
          Site={"https://fin-stream.streamlit.app/"}
          body={
            <span>
              A financial themed <a href="https://streamlit.io/" target="_blank" rel="noreferrer">Streamlit</a>
              &nbsp;application that includes a live stock ticker, stock market dashboard, 
              and direct access to the latest&nbsp;
              <a href="https://www.sec.gov/" target="_blank" rel="noreferrer">SEC</a> 
              &nbsp;recent corporate filings.
            </span>
          }
        />
        <Card 
          heading="Plotly Indicator Gauge for Streamlit"
          Icon={"streamlit-logo-primary-colormark-darktext.png"}
          IconHeight={"270"}
          IconWidth={"270"}
          SiteText={"View App"}
          AltText={"Streamlit Application"}
          Site={"https://stream-gauge.streamlit.app/"}
          body={
            <span>
              A <a href="https://streamlit.io/" target="_blank" rel="noreferrer">Streamlit</a>                
              &nbsp;application for a <a href="https://plotly.com/" target="_blank" rel="noreferrer">Plotly</a>
              &nbsp;Indicator Gauge module built
              using the <a href="https://www.python.org/" target="_blank" rel="noreferrer">Python</a> programming language.
            </span>
          }
        />
      </div>

      <div id="TL_Card" class="uk-flex uk-flex-center">
        <AltCard 
          heading="NFL Player Performance Dashboard"
          Icon={"tableau.png"}
          IconHeight={"160"}
          IconWidth={"160"}
          SiteText={"View Dashboard"}
          AltText={"Football Stats"}
          Site={"https://public.tableau.com/views/NFLRushingReceivingPlayerPerformance/CoverPage?:embed=y&:language=en-US&:sid=6DFDEA4E701548B280D6C1127C6D1061-0:0&:display_count=n&:origin=viz_share_link"}
          body={
            <span>
              An interactive&nbsp;
              <a href="https://www.tableau.com/" target="_blank" rel="noreferrer">Tableau</a> 
              &nbsp;dashboard containing&nbsp;
              <a href="https://www.nfl.com/" target="_blank" rel="noreferrer">NFL</a> 
              &nbsp;rushing and receiving statistics prior to the 2023 season for individual players, hosted on&nbsp;
              <a href="https://public.tableau.com/app/discover" target="_blank" rel="noreferrer">Tableau Public</a>
              .
            </span>
          }
        />

        <Card 
          heading="Traffic Light App"
          Icon={"Traffic-Light-Image.png"}
          IconHeight={"160"}
          IconWidth={"110"}
          SiteText={"View App"}
          AltText={"Traffic Light App"}
          Site={"https://trafficlight.techbyderek.com/"}
          body={
            <span>
              An interactive traffic light animation app built using&nbsp;
              <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a>
              &nbsp;and&nbsp;
              <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap</a>
              .
            </span>
          }
        />

        <Card 
          heading="Calculator App"
          Icon={"Calc_192-134.png"}
          IconHeight={"180"}
          IconWidth={"125"}
          SiteText={"View App"}
          AltText={"Calulator"}
          Site={"https://calculator.techbyderek.com/"}
          body={
            <span>
              An interactive calculator web application built using&nbsp;
              <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a>
              &nbsp;and&nbsp;
              <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap</a>
              .
            </span>
          }
        />
      </div>

      <div id="TL_Card" class="uk-flex uk-flex-center">
        <Card 
          heading="Resume"
          Icon={"Resume_Icon.svg"}
          IconHeight={"220"}
          IconWidth={"180"}
          SiteText={"View Page"}
          AltText={"Resume"}
          Site={"https://resume.techbyderek.com/"}
          body={<span>Online Resume</span>}
        />

        <Card 
          heading="Legacy Portfolio"
          Icon={"notion-logo-no-background.png"}
          IconHeight={"220"}
          IconWidth={"180"}
          SiteText={"View Page"}
          AltText={"Notion"}
          Site={"https://affiliate.notion.so/notion_portfolio_site_derek_evans"}
          body={
            <span>Legacy portfolio hosted on&nbsp;
              <a href="https://affiliate.notion.so/notion_projects_home_page" alt="Link to main home page for Notion."  target="_blank" rel="noreferrer">Notion</a>
              <br/>
            </span>
          }
        />
      </div>

      <div id="footer">
        <a class="nav-link link-light" href="https://www.techbyderek.com">
          <img id="de-logo" src="D LOGO WHITE - LOGO ONLY - 360 - NO BG.png" alt="Logo for Derek Evans" height="100em" width="100em" />
        </a>
      </div>

    </div>

  );
}

export default App;
