function AltCard(props) {

    return(

        <div id="TL_Card_Content" class="card">
            <div class="card-header">
                <div id="cardHeader">
                    <img id="cardImg" width={props.IconWidth} height={props.IconHeight} src={props.Icon} alt="Light" />
                    <p id="cardTitle" class="card-title h4">{props.heading}</p>
                </div>
            </div>
            <div class="card-body">
                <p class="card-text">{props.body}</p>
            </div>
            <div class="card-footer">
                <a href={props.Site} class="btn btn-primary">{props.SiteText}</a>
            </div>
        </div>

    )

}

export default AltCard;
